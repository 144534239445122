import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { useState } from "react";

export default function SNSForm(props) {
  const api_url =
    "https://ofw70zrc1h.execute-api.us-east-1.amazonaws.com/default/messages";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  function submitForm(e) {
    e.preventDefault();
    let body = {
      name: name,
      email: email,
      subject: subject,
      message: message,
    };
    let options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json;",
      },
    };
    fetch(api_url, options)
      .then((response) => {
        if (response.ok) {
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          response.json();
          props.toggleModal({
            isOpen: true,
            title: "Message Sent",
            body: "Message has been sent succesfully",
            icon: "",
          });
        } else {
          alert(
            "An error occurred while sending your message. Please try again later"
          );
        }
      })
      .then((response) => {
        console.log("response:", response);
      });
  }

  return (
    <div className="form-container">
      <h3 className="center">Try it now, send me a message!</h3>
      <Form className="form" onSubmit={submitForm}>
        <FormGroup>
          <Label htmlFor="name" className="form-Label">
            Name:
          </Label>
          <Input
            type="text"
            className="form-control"
            name="name"
            id="name"
            placeholder="Your name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="email" className="form-Label">
            Email address:
          </Label>
          <Input
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder="name@example.com"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="subject" className="form-Label">
            Subject:
          </Label>
          <Input
            type="text"
            className="form-control"
            name="subject"
            id="subject"
            placeholder="Subject"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
          />
        </FormGroup>

        <div className="mb-3">
          <Label htmlFor="message" className="form-Label">
            Message:
          </Label>
          <textarea
            style={{ maxWidth: "500px", margin: "0 auto" }}
            className="form-control"
            id="message"
            placeholder="Your message..."
            name="message"
            rows="4"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          ></textarea>
        </div>

        <FormGroup>
          <Button onClick={submitForm} size="lg" className="btn btn-secondary">
            Send
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
}
