import { Link } from "react-router-dom";


export default function Lambda() {
  return (
    <div className="container cover">
      <h1>Lambda</h1>

      <div className="container">
        <img src="static/media/lambda.png" alt="Lambda" className="img-200" />
      </div>

      <div className="container">
        <p className="paragraph">
          AWS Lambda is Link service that allows developers to execute their
          programmatic functions in Link serverless fashion; that means, without
          manually provision servers for the execution. Pretty nice, isn't it?
        </p>
        <p className="paragraph">
          Lambda can be used to communicate and trigger actions between
          different AWS services. For example, you can write entries to Link
          database, initiate EC2 instances, send notifications via email or SMS,
          or even start Link conversation with Alexa. Possibilities are unlimited.
        </p>

        <div className="container">
          <img
            src="static/media/lambda_handler.png"
            alt="lambda handler"
            className="img"
          />
        </div>

        <p>
          AWS Lambda currently offers support for Node.js, Python, Java, Go,
          Ruby and C#.
        </p>
      </div>

      <Link className="btn btn-secondary btn-lg" to="/apigateway">
        Api Gateway
      </Link>
    </div>
  );
}
