import { Link } from "react-router-dom";

function Home(){
  return (
    <div className="container cover">
      <h1>Welcome!</h1>

      <p className="lead">
        Hi! My name is Gustavo Allfadir, I'm a software engineer. I've recently
        acquired my certification as AWS Solutions Architect Associate and I'm
        creating this serverless site using Amazon Web Services solutions.
      </p>

      <div className="container">
        <img
          src="static/media/perfil.jpg"
          alt="Gustavo Allfadir"
          className="img-thumbnail"
        />
      </div>

      <p className="lead">
        But then, someone might ask: <i>What does "serverless" mean?</i>
      </p>

      <p className="lead">Well, let me explain it for you...</p>

      <Link className="btn btn-secondary btn-lg" to="/serverless">
        Learn more
      </Link>
    </div>
  );
}

export default Home;