import SNSForm from "./SNSForm";

export default function SNS(props) {
  return (
    <div className="container cover">
      <h1>SNS, Simple Notification System</h1>

      <div className="container">
        <img src="static/media/sns.png" alt="SNS" className="img-200" />
      </div>

      <p className="paragraph">
        Amazon Simple Notification Service (SNS) is a managed service that
        provides message delivery from publishers to subscribers. Publishers
        communicate asynchronously with subscribers by sending messages to a
        topic; this may remind you of mailing lists or SMS subscriptions you
        might already know and use; well, with SNS you can implement this
        functionality on your own services or applications.
      </p>

      <SNSForm toggleModal={props.toggleModal} />
    </div>
  );
}
