import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Modal from "./components/Modal/Modal";
import "./static/css/styles.css";
import { app_routes } from "./routes";

function App() {
  const [modal, toggleModal] = useState({isOpen:false,title:"",body:"",icon:""});

  return (
    <div className="h-100">
      <div className="d-flex h-100 text-center text-white">
        <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
          <BrowserRouter>
            <Modal isOpen={modal.isOpen} data={modal} toggleModal={toggleModal}/>
            <Header />
            <main className="px-3">
              <div className="container">
                <Routes>
                  {app_routes.map((route, index) => 
                    <Route
                      exact
                      key={index}
                      path={route.path}
                      element={<route.element toggleModal={toggleModal} />}
                    />
                  )}
                </Routes>
              </div>
            </main>
            <Footer />
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
}

export default App;
