import { React, Component } from "react";
import { Button } from "reactstrap";

class BooksForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      author: "",
      year: "",
      country: "",
      language: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  resetForm() {
    this.setState({
      name: "",
      author: "",
      year: "",
      country: "",
      language: "",
    });
  }

  render() {
    return (
      <div className="form-container">
        <form
          onSubmit={() => {
            this.props.postDynamo(this.state).then(this.resetForm());
          }}
          className="form"
          onChange={this.handleInputChange}
        >
          <div className="form-group">
            <label htmlFor="name">Book name:</label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Book's name"
              className="form-control"
              onChange={this.handleInputChange}
              value={this.state.name}
            />
          </div>

          <div className="form-group">
            <label htmlFor="author">Author:</label>
            <input
              type="text"
              name="author"
              id="author"
              placeholder="Author's name"
              className="form-control"
              onChange={this.handleInputChange}
              value={this.state.author}
            />
          </div>

          <div className="form-group">
            <label htmlFor="year">Year:</label>
            <input
              type="number"
              name="year"
              id="year"
              placeholder="Year of publishing"
              className="form-control"
              onChange={this.handleInputChange}
              value={this.state.year}
            />
          </div>

          <div className="form-group">
            <label htmlFor="country">Country:</label>
            <input
              type="text"
              name="country"
              id="country"
              placeholder="Country of origin"
              className="form-control"
              onChange={this.handleInputChange}
              value={this.state.country}
            />
          </div>

          <div className="form-group">
            <label htmlFor="language">Language:</label>
            <input
              type="text"
              name="language"
              id="language"
              placeholder="Original language"
              className="form-control"
              onChange={this.handleInputChange}
              value={this.state.language}
            />
          </div>
          <div className="form-group">
            <Button
              className="btn btn-lg btn-secondary"
              onClick={() => {
                this.props.postDynamo(this.state).then(this.resetForm());
              }}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default BooksForm;
