import { useState } from "react";
import { Table } from "reactstrap";
import "./BooksTable.css";

export default function BooksTable(props) {
  const [sortValue, setSortValue] = useState(null);
  const [willReverse, setWillReverse] = useState(false);
  const [sortedData, setSortedData] = useState(null);
  let data = sortedData ? sortedData : props.data;
  const headers = [
    {
      name: "Name",
      value: "name",
    },
    {
      name: "Author",
      value: "author",
    },
    {
      name: "Year",
      value: "year",
    },
    {
      name: "Country",
      value: "country",
    },
    {
      name: "Language",
      value: "language",
    },
  ];

  function sortData(array, sortVal, willReverse) {
    if (!Array.isArray(array)) {
      console.error("Error while sorting array. Verify input.");
    }
    
    let output = array.sort((a, b) => {
      let typeA = Object.keys(a[sortVal])[0];
      let typeB = Object.keys(b[sortVal])[0];
      let valA = a[sortVal][typeA];
      let valB = b[sortVal][typeB];
      return valA > valB ? -1 : valA < valB ? 1 : 0;
    });
    
    output = output.sort((a, b) => {
      let typeA = Object.keys(a[sortVal])[0];
      let typeB = Object.keys(b[sortVal])[0];
      let valA = a[sortVal][typeA];
      let valB = b[sortVal][typeB];
      return valA > valB ? -1 : valA < valB ? 1 : 0;
    });

    if (willReverse) {
      output.reverse();
    } 
    console.log(willReverse);
    setSortedData([]);
    setSortedData(output);
  }

  async function handleSort(sortVal) {
    await setSortValue(sortVal);
    await sortData(props.data, sortValue, willReverse);
    setWillReverse(!willReverse);
  }

  return (
    <Table hover={false} responsive={true}>
      <thead>
        <tr>
          {headers.map((val, index) => (
            <th
              key={index}
              onClick={() => {
                handleSort(val.value);
              }}
            >
              {val.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((book, index) => (
          <tr key={index}>
            <td>{book.name.S}</td>
            <td>{book.author.S}</td>
            <td>{book.year.N ? book.year.N : book.year.S}</td>
            <td>{book.country.S}</td>
            <td>{book.language.S}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
