import { Link } from "react-router-dom";

function Serverless() {
  return (
    <div className="container cover">
      <h1>Serverless computing</h1>
      <img
        src="static/media/serverless.png"
        alt="Serverless"
        className="img-200"
      />

      <div className="container">
        <p className="paragraph">
          Serverless computing is a cloud computing execution model in which the
          cloud provider allocates machine resources on demand, taking care of
          the servers on behalf of their customers. This doesn't mean that there
          are no servers... It's just that you'll never actually need to worry
          about them, AWS will manage them all for you.
        </p>
        <p className="paragraph">
          Serverless computing on AWS ins possible thanks to a variety of
          services for different purposes, all working together in a serverless
          architecture schema.
        </p>

        <p className="paragraph">Some examples for these services are:</p>
        <ul className="paragraph">
          <li>
            <Link className="link" to="/lambda">
              AWS Lambda
            </Link>
          </li>
          <li>
            <Link className="link" to="/api">
              Api Gategay
            </Link>
          </li>
          <li>
            <Link className="link" to="/dynamodb">
              DynamoDB
            </Link>
          </li>
          <li>
            <Link className="link" to="/s3">
              S3
            </Link>
          </li>
          <li>
            <Link className="link" to="/sns">
              SNS
            </Link>
          </li>
        </ul>
      </div>

      <p className="paragraph">
        For now, let's focus in the core and soul of AWS serverless solutions...
      </p>

      <Link to="/lambda" className="btn btn-secondary btn-lg">
        Lambda
      </Link>
    </div>
  );
}

export default Serverless;
