function Footer() {
  return (
    <footer className="mt-auto text-white-50">
      <hr></hr>
      <div className="center">
        <img className="img-thumbnail" src="static/media/aws.jpg" alt="AWS" />
        <img
          className="img-thumbnail"
          src="static/media/AWS-SAA.png"
          alt="AWS Certified Solutions Architect Associated"
        />
        <p>Gustavo Allfadir, 2021</p>
        <p>AWS Certified Solutions Architect Associated</p>
        <p>gustavo.allfadir@gmail.com</p>
      </div>
    </footer>
  );
}

export default Footer;
