import { app_routes } from "../../routes";
import { Link, useLocation } from "react-router-dom";

function setActiveClass(current_path, route_path){
  if (current_path === route_path){
    return "nav-link active"
  }
  return "nav-link"
}

function Header(){
  const path = useLocation().pathname;
  return (
    <header className="mb-auto">
      <div>
        <h3 className="float-md-start mx-auto">My AWS Serverless Site</h3>
        <nav className="nav nav-masthead justify-content-center float-md-end">
          {app_routes.map((route, index) => (
            <Link
              key={index}
              className={setActiveClass(path, route.path)}
              aria-current="page"
              to={route.path}
            >
              {route.name}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
