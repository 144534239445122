import Home from "./pages/Home/Home";
import Serverless from "./pages/Serverless/Serverless";
import Lambda from "./pages/Lambda/Lambda";
import ApiGateway from "./pages/ApiGateway/ApiGateway";
import DynamoDB from "./pages/DynamoDB/DynamoDB";
import S3 from "./pages/S3/S3";
import SNS from "./pages/SNS/SNS";

const app_routes = [
    {
        name : "Home",
        path : "/",
        element : Home
    },
    {
        name : "Serverless",
        path : "/serverless",
        element : Serverless
    },
    {
        name : "Lambda",
        path : "/lambda",
        element : Lambda
    },
    {
        name : "ApiGateway",
        path : "/apigateway",
        element : ApiGateway
    },
    {
        name : "DynamoDB",
        path : "/dynamodb",
        element : DynamoDB
    },
    {
        name : "S3",
        path : "/s3",
        element : S3
    },
    {
        name : "SNS",
        path : "/sns",
        element : SNS
    },
]

export {app_routes};