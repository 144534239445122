import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BooksForm from "./BooksForm";
import BooksTable from "./BooksTable";

export default function DynamoDB(props) {
  const api_url = "https://ofw70zrc1h.execute-api.us-east-1.amazonaws.com/default/books";
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);

  function getDynamo() {
    setFetching(true);
    fetch(api_url)
      .then((response) => response.json())
      .then((response) => {
        setFetching(false);
        setData(response.body.Items);
      });
  }

  async function postDynamo(body) {
    const options = {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-type": "application/json;",
      },
    };
    fetch(api_url, options)
      .then((response) => response.json())
      .then((response) => {
        props.toggleModal({
          isOpen: true,
          title: "Saved",
          body: "Book record has been saved into DynamoDB",
          icon: "",
        });
        getDynamo();
      });
  }

  useEffect(() => {
    getDynamo();
  }, []);

  return (
    <div className="container cover">
      <h1>DynamoDB</h1>

      <div className="container">
        <img
          src="static/media/dynamodb.png"
          alt="DynamoDB"
          className="img-200"
        />
      </div>

      <p className="paragraph">
        DynamoDB is a serverless NoSQL database service provided by AWS. It's
        efficient, scalable and highly available, so the developer and the end
        user don't even need to worry about it, in a few steps services will be
        connected and ready to store and retrieve data from the cloud.
      </p>

      <p className="paragraph">
        Let's think for example you're making a database of books that are
        favorites among your users community. You could store that data in a{" "}
        <i>key:value</i> pattern that stores the book's author, name, country,
        original language and year of publishing.
      </p>

      <p className="paragraph">
        Let's fill a little form with that data and retrieve it into DynamoDB:
      </p>

      <BooksForm postDynamo={postDynamo} />

      <h3>Now let's take a look inside the database content:</h3>

      {fetching ? <p>Loading...</p> : <BooksTable data={data} />}

      <br />
      <p className="paragraph">
        Just like that, you've uploaded some data into a database that needs no
        server to be managed. That's as simple as it gets. But wait, there's
        more!
      </p>

      <Link className="btn btn-secondary btn-lg" to="/s3">
        Simple Storage System
      </Link>
    </div>
  );
}
