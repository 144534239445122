import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  CardTitle,
  Button,
  CardFooter,
} from "reactstrap";
import "./Modal.css";

export default function MyModal(props) {
  return (
    <Modal
      autoFocus={true}
      isOpen={props.isOpen}
      centered={true}
      backdrop={true}
    >
      <Card className="text-center">
        <CardHeader>
          <CardTitle tag="h2">{props.data.title}</CardTitle>
        </CardHeader>
        <CardBody>
          {props.data.body}
          <i className={props.data.icon} />
        </CardBody>
        <CardFooter>
          <Button
            className="text-center"
            onClick={() => {
              props.toggleModal({ isOpen: false });
            }}
          >
            Close
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  );
}
