import { Link } from "react-router-dom";

export default function S3() {
  return (
    <div className="container cover">
      <h1>S3, Simple Storage Service</h1>

      <div className="container">
        <img src="static/media/s3.png" alt="S3" className="img-200" />
      </div>

      <p className="paragraph">
        Amazon S3 or Amazon Simple Storage Service is a service offered by
        Amazon Web Services that provides object storage through a web service
        interface.
      </p>

      <p className="paragraph">
        What do I mean by an "object"? Well, it could usually be any file, but
        actually any kind of data is storable as a S3 object; from small log
        files up to complete block storage snapshots.
      </p>

      <p className="paragraph">
        Pretty cool, right? As simple as just throwing something into a bucket.
        Now let's advance into the last part of this site.
      </p>

      <h3>Static web hosting</h3>

      <p className="paragraph">
        AWS S3 can be used to host static web pages with pictures and multimedia files without the 
        need of running a web server for this purpose. Then you can deliver it all around the world
        using CloudFront and the Edge global network of AWS. 
        <br />
        <br /> 
        This website is fully hosted on S3 and delivered with CloudFront!
      </p>

      <Link className="btn btn-secondary btn-lg" to="/sns">
        Simple Notification System
      </Link>
    </div>
  );
}
