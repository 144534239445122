import { Link } from "react-router-dom";

export default function ApiGateway() {
  return (
    <div className="container cover">
      <h1>API Gateway</h1>

      <div className="container">
        <img src="static/media/api.png" alt="API Gateway" className="img-200" />
      </div>

      <p className="paragraph">
        Amazon API Gateway is an AWS service for creating, publishing,
        maintaining, monitoring, and securing REST, HTTP, and WebSocket APIs at
        any scale. API developers can create APIs that access AWS or other web
        services, as well as data stored in the AWS Cloud.
      </p>

      <p className="paragraph">
        In the next sections we're going to see API Gateway and Lambda in
        action, serving as interface and connection bridge between this site and
        services like DynamoDB, S3 and SNS.
      </p>

      <Link className="btn btn-secondary btn-lg" to="/dynamodb">
        DynamoDB
      </Link>
    </div>
  );
}
